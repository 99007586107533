export const prepend0 = (str) => {
  return ("0" + str).slice(-2);
};

export const formatDate = (
  date,
  separator = "/",
  invertDayAndMonth = false,
  invertAll = false
) => {
  if (date) {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const dateArray = invertDayAndMonth
      ? [prepend0(month), prepend0(day), year]
      : [prepend0(day), prepend0(month), year];
    if (invertAll) dateArray.reverse();
    return dateArray.join(separator);
  }
};

export const formatDateText = (dateStr, lang = "fr") => {
  if (dateStr) {
    const date = new Date(dateStr);
    const dayOfMonth = date.getDate();
    const day = date.getDay();
    const month = date.getMonth();
    const dateArray = [days[lang][day], dayOfMonth, months[lang][month]];

    return dateArray.join(" ");
  }
};

const days = {
  fr: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
  en: [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ],
  ca: [
    // Catalan
    "diumenge",
    "dilluns",
    "dimarts",
    "dimecres",
    "dijous",
    "divendres",
    "dissabte",
  ],
  es: [
    // Espagnol
    "domingo",
    "lunes",
    "martes",
    "miércoles",
    "jueves",
    "viernes",
    "sábado",
  ],
  it: [
    // Italien
    "domenica",
    "lunedì",
    "martedì",
    "mercoledì",
    "giovedì",
    "venerdì",
    "sabato",
  ],
  nl: [
    // Néerlandais
    "zondag",
    "maandag",
    "dinsdag",
    "woensdag",
    "donderdag",
    "vrijdag",
    "zaterdag",
  ],
};

const months = {
  fr: [
    "janvier",
    "février",
    "mars",
    "avril",
    "mai",
    "juin",
    "juillet",
    "août",
    "septembre",
    "octobre",
    "novembre",
    "décembre",
  ],
  en: [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
  ],
  ca: [
    // Catalan
    "gener",
    "febrer",
    "març",
    "abril",
    "maig",
    "juny",
    "juliol",
    "agost",
    "setembre",
    "octubre",
    "novembre",
    "desembre",
  ],
  es: [
    // Espagnol
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ],
  it: [
    // Italien
    "gennaio",
    "febbraio",
    "marzo",
    "aprile",
    "maggio",
    "giugno",
    "luglio",
    "agosto",
    "settembre",
    "ottobre",
    "novembre",
    "dicembre",
  ],
  nl: [
    // Néerlandais
    "januari",
    "februari",
    "maart",
    "april",
    "mei",
    "juni",
    "juli",
    "augustus",
    "september",
    "oktober",
    "november",
    "december",
  ],
};
